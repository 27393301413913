$('.file-field').each(function() {
	var container = $(this);
  var input 	= container.find('.file-input');
  var output 	= container.find('.file-output');
  
  input.change(function() {
  	var file = this.files[0];
    
    output.text(file.name);
    container.addClass('focus');
  });
});