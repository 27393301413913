var selectEstado = (function() {
	var $select 	= $('.select-estado select');
	var $mapa 		= $('#mapa-brasil');
	var $estado		= $mapa.find('g');

	$estado.click(function() {
		var val = $(this).data('value');

		$select.val(val).trigger('change');
	});

	$select.on('change', function() {
		var val = $(this).val();

		$estado.removeAttr('class');
		$('g[data-value="'+val+'"]').attr('class','active');
	});
})();